import React, { useEffect } from 'react';

import { Main, Meta } from '@layout';
import { MainBanner, Slogans, Usages } from '@components';

const Index = () => {
  useEffect(() => {
    document.getElementsByTagName('body')[0].style.overflow = 'auto';
  });
  return (
    <Main
      meta={
        <Meta
          title="세금포인트 할인쇼핑몰"
          description="납세자가 보유하고 있는 세금포인트를 사용하여 중소기업 제품을 할인받아 구매할 수 있는
        세금포인트 할인쇼핑몰"
          canonical="https://www.taxpoint.or.kr"
        />
      }
    >
      <MainBanner />
      <Usages />
      <Slogans />
    </Main>
  );
};

export default Index;
